import { PATH } from 'constants/path';
import React, { useEffect } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { FaCartPlus } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatCurrency } from 'utils/commonUtils';

function ProductItem({
  product,
  handleAddToCartButton,
  hasCartButton = true,
  displayPrice = true,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [addedToCart, setAddedToCart] = React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAddedToCart(false);
    }, 2000);
  }, [addedToCart]);

  return (
    <div className='h-100 d-flex flex-column justify-content-between'>
      <div
        onClick={() =>
          navigate(
            PATH.APP.PRODUCT_DETAIL.replace(':productId', product?._id),
            {
              state: { coupon: location?.state?.coupon },
            }
          )
        }
      >
        <div className='p-0'>
          <div className='w-100 mb-2'>
            <Image src={product.image} className='w-100 rounded' />
          </div>
          <h6 className='text-center'>{product.name}</h6>
        </div>
      </div>
      <div className='d-flex justify-content-between align-items-end'>
        {displayPrice && (
          <div>
            {product?.originalPrice > 0 &&
              product?.originalPrice !== product?.price && (
                <div className='text-decoration-line-through'>
                  <small>{formatCurrency(product?.originalPrice)} đ</small>
                </div>
              )}
            <div className='text-danger'>
              <p className='fw-bold p-0 m-0 h6'>
                {formatCurrency(product.price)} đ
              </p>
            </div>
          </div>
        )}
        {hasCartButton && (
          <div>
            {addedToCart ? (
              <Button
                variant='outline-primary'
                onClick={() => handleAddToCartButton(product)}
              >
                <FaCartPlus />
              </Button>
            ) : (
              <Button
                variant='outline-primary'
                onClick={() => {
                  handleAddToCartButton(product);
                  setAddedToCart(true);
                }}
              >
                <FaCartPlus />
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductItem;
