import React from 'react';
import A1Driving from '../A1Driving';

function AdminA1DrivingPage() {
  return (
    <div
      style={{
        height: '100vh',
        overflow: 'scroll',
      }}
    >
      <A1Driving />
    </div>
  );
}

export default AdminA1DrivingPage;
