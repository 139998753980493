import React from 'react';
import B2Driving from '../B2Driving';

function AdminB12DrivingPage() {
  return (
    <div
      style={{
        height: '100vh',
        overflow: 'scroll',
      }}
    >
      <B2Driving />
    </div>
  );
}

export default AdminB12DrivingPage;
