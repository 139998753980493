export const light = {
  id: "T001",
  name: "theme-light",
  color: {
    header: {
      background: {
        mobile: "teal",
        desktop: "teal"
      },
      text: {
        mobile: "white",
        desktop: "white"
      },
      button : {
        mobile: "white",
        desktop: "white"
      }
    },
  },
}
