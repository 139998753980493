import React from 'react'
import Maintained from './Maintained'
import TitleBar from 'components/TitleBar'

function MaintainPage() {
  return (
    <div>
      <Maintained />
    </div>
  )
}

export default MaintainPage
