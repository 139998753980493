const theme = {
    colors: {
        powderWhite: "#FFFDF9",
        white: '#FFF',
        persianGreen: "#06B49A",
        lightBlue: "#AFDBD2",
        onyx: "#36313D",
        vividRed: "#db2828",
        charcoalDark:'#333',
        gainsboro: '#dddddd',
        gray: '#777',
        lightGray: '#e0e1e2',
        blueGray: '#00000099',
        teal: '#008080',
        whiteSmoke: '#f5f5f5',
    },
    fonts: ["Roboto Condensed", "sans-serif"],
    fontSizes: {
        small: "1em",
        medium: "2em",
        large: "3em"
    }
}

export default theme;
