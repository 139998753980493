import React from 'react'

function Maintained() {
  return (
    <div
      style={{
        textAlign: 'center',
        minHeight: '70vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <p>
        Tính năng này đang được phát triển
        <br />
        Vui lòng quay lại sau.
      </p>
    </div>
  )
}

export default Maintained
