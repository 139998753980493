import React from 'react';
import A2Driving from '../A2Driving';

function AdminA2DrivingPage() {
  return (
    <div
      style={{
        height: '100vh',
        overflow: 'scroll',
      }}
    >
      <A2Driving />
    </div>
  );
}

export default AdminA2DrivingPage;
